.light-round-shadow{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.black-svg svg {
    filter : brightness(0) invert(0);
}
.white-svg svg {
    filter : brightness(0) invert(100%);
}
.space{
    margin-left: 280px;
}

@media (max-width: 768px){
    .space{
        margin-left: 0;
    }
}


/* Table */
table {
    overflow-x: auto;
    width : 100%;
}

@media (max-width: 1250px){
    table {
        /* display: block; */
        white-space: nowrap;
    }
}
