:root {
  --primary : #046F6D;
  --grayText : #A8A8B3;
  --pure : #fff;
  --dark : #000;
  --light : rgb(231, 231, 231);
}

body {
  font-family: 'Inter', sans-serif;
}
