.students-list::-webkit-scrollbar {
    display: none;
}

.schedule-calender .fc-event{
    background-color : var(--primary) !important;
    display: flex;
    align-items: flex-start;
    gap: 2px;
    color: var(--pure) !important;
    padding: 4px;
    cursor:pointer;
    word-break: break-all !important;
    
}

.schedule-calender .fc-event-title {
    color: var(--pure) !important;
    font-weight: normal !important;
}