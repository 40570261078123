::-webkit-scrollbar{
    width: 8px;
}
::-webkit-scrollbar-thumb{
    background-color: var(--primary);
    border-radius: 10px ;
}
.sidebar{
    transition: 0.3s all ease-in-out;
}
.sidebar.show{
    left : 0;
}
.sidebar > ul li.active {
    background-color: var(--primary);
    color : var(--pure);
}
.sidebar ul li div.active svg {
    filter : brightness(0) invert()
}
.sidebar > ul li:hover:not(.booking-drop-down) svg , .sidebar ul li.active svg {
    filter : brightness(0) invert();
}