.pastExamSwiper{
    width: 100%;
    height : 100%;
}

.fc-toolbar {
    font-size: 14px !important;
}
.fc-toolbar-title{
    font-size: 24px !important;
    font-weight: 600;
}
.fc-toolbar-chunk .fc-button-group button{
    background-color: var(--primary) !important;
    font-size: 13px !important;
}
.fc-toolbar-chunk .fc-prev-button , .fc-toolbar-chunk .fc-next-button {
    background-color: var(--pure) !important;
    border : 1px solid rgb(211, 211, 211) !important;
    padding: 2px 10px !important;
    outline : none !important;
}
.fc-toolbar-chunk .fc-prev-button:hover , .fc-toolbar-chunk .fc-next-button:hover {
    background-color: rgb(219, 219, 219) !important;
}
.fc-toolbar-chunk .fc-prev-button span , .fc-toolbar-chunk .fc-next-button span {
    font-size: 16px !important;
    color : black;
    transform : translateY(-2px);
    outline : none;
}

.fc-col-header-cell{
    font-weight: 600;
    font-size: 14px;
    padding: 14px 0 !important;
    border:none;
}
.fc-col-header {
    background-color: rgb(241, 241, 255);
}
.fc-daygrid-day-top{
    font-size: 16px;
}
.fc-daygrid-day{
    width: 20px !important;
}
 .fc-event{
    background-color: rgb(234, 234, 253) ;
    color : var(--primary) !important;
    border-left: 3px solid var(--primary);
    padding-left: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    border-top: none;
    border-right : none;
    border-bottom: none;
    padding: 8px 4px;
    cursor:pointer;
    word-break: break-all !important;
}
.fc-event-title{
    font-weight: 500 !important;
    color : var(--primary);
    word-break: break-word !important;
    overflow-x: auto;
}
.fc-daygrid-event-dot{
    display: none;
}



.fc-daygrid{
    overflow-x: scroll !important;
}
.fc-daygrid table {
    min-width: 650px !important;
    white-space: nowrap !important;
    overflow-x: scroll !important;
}
@media (max-width: 1024px){
    .fc-toolbar-title{
        font-size: 22px !important;
    }
}

@media (max-width: 768px){
    .fc-toolbar-title{
        font-size: 20px;
    }
}

@media (max-width: 550px){
    .fc-toolbar-title{
        font-size: 16px !important;
    }
    .fc-toolbar-chunk .fc-prev-button , .fc-toolbar-chunk .fc-next-button {
        padding: 2px 5px !important;
    }
    .fc-toolbar-chunk .fc-button-group button{
        font-size: 10px !important;
        padding: 2px 4px;
    }
    .fc-event{
        font-size: 10px;
    }
}
