.pagination li{
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    color: black;
    cursor : pointer;
}
.pagination li:not(.next , .previous):hover{
    background-color: var(--primary);
    color: white;

}
.pagination .selected {
    background-color: var(--primary);
    color : var(--pure);
}
.pagination .next , .pagination .previous{
    border : none ;
    color : var(--primary);

}