@tailwind base;
@tailwind components;
@tailwind utilities;


.login-background {
    height: 50vh;
    background-image: url("./pages/Register/register_header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}
.profile-background {
  height: 20vh;
    background-image: url("./assets/img/profileImage.png");
    background-repeat: no-repeat;
    /* background-size: cover; */
}

    /* fullCalendar */
    .fc .fc-daygrid-day.fc-day-today {
        background-color: unset !important;
    }
    .uppercase {
        text-transform: unset  !important;
    }

@layer components {
    /* Stepper Style */
    .div-main-stepper {
        @apply transform -translate-y-20 mr-0 rounded-md bg-white  container border border-x-gray-100 backdrop-blur-[8px]
    }
    .div-main-stepper-admin {
        @apply transform  rounded-md bg-white shadow-sm container border border-gray-400 mt-8
    }
    .div-sub-stepper {
        @apply flex items-center
    }
    .div-steps-style {
        @apply rounded-full transition duration-500 ease-in-out border-2 border-gray-300 h-12 w-12 flex items-center justify-center py-3
    }
    .div-selected-step {
        @apply bg-[#046F6D] text-white font-bold border border-[#046F6D]
    }
    /* Wizard Reg. Style  */

    .div-main-admin {
        @apply md:mx-16 sm:mx-8 mx-3;
    }
    .div-wizard {
        @apply my-5 py-2 bg-white;
    }
    .div-section-wizard {
        @apply grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4
    }
    .hr-wizard {
        @apply my-8 h-px border-0 bg-[#CFCFCF]
    }
    .header-wizard {
        @apply leading-none text-4xl font-bold mb-[15px];
    }
    .header-wizard-upload {
        @apply leading-none text-4xl font-bold mb-[15px];
    }
    .div-input-wizard {
        @apply my-2 mt-0 mb-0 flex rounded border border-[#CFCFCF] bg-white p-1;
    }

    .inputWrapper {
        @apply px-[20px] mt-[5px] placeholder:text-[#262D33] placeholder:font-normal placeholder:text-xs w-full h-[50px] flex rounded border  border-[#CFCFCF] bg-white  focus:outline-none ;
    }
    .inputError {
        @apply px-[20px] mt-[5px] placeholder:text-[#262D33] placeholder:font-normal placeholder:text-xs w-full h-[50px] flex rounded border  border-[red] bg-white focus:outline-none ;
    }
    .address_details_textarea {
        @apply border-[#CFCFCF] w-full border rounded px-[16px] py-[11.08px] text-xs h-[85px] resize-none outline-none placeholder:text-[#262D33]
    }
    .students_address_details_textarea {
        @apply mt-4 border-[#CFCFCF] w-full border rounded px-[16px] py-[11.08px] text-xs h-[85px] resize-none outline-none placeholder:text-[#262D33]

    }
    .company-logo-input-wizard {
        @apply bg-[#fff] flex items-center justify-center border-dashed border-[#BFBFBF] border-2 w-[162px] h-[130px]
    }
    .upload-input-wizard {
        @apply bg-[#F4F5F6] flex items-center justify-center border-dashed border-2 border-[#BFBFBF] w-full h-[182px] rounded-lg
    }
    .targeted-label {
        @apply text-[#777E91] text-center text-sm mt-[5.25px] font-normal
    }
    .company-targeted-label {
        @apply text-[#333333] text-center text-sm mt-[5.25px] font-normal
    }
    .input-wizard {
        @apply w-full appearance-none p-1 px-2 text-gray-800 outline-none;
    }
    .label-wizard {
        @apply font-medium text-sm  text-[#141522]
    }
    .input-error-span {
        @apply mt-3 h-6 text-xs text-red-500
    }
    .radio-label-wizard {
        @apply ml-4 text-sm font-medium text-gray-900 dark:text-gray-300;
    }
    .div-radio-wizard {
        @apply my-2 bg-white flex items-center mb-4 px-2 p-1
    }
    .label-radio-wizard {
        @apply ml-4 text-sm font-medium text-gray-900 dark:text-gray-300;
    }
    .input-radio-wizard {
        @apply ml-12 w-5 h-4 text-[#051033] accent-[#051033] bg-gray-100 border-[#051033] focus:ring-[#051033] dark:focus:ring-[#051033] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-[#051033];
    }
    .div-dropdown-wizard {
        @apply my-2 flex rounded border border-[#CFCFCF] bg-white p-1 hover:bg-gray-50
    }
    .select-dropdown-wizard {
        @apply w-full p-1 px-2 text-gray-800 outline-none bg-white hover:cursor-pointer
    }

    /* Stepper */

    .step-item {
        @apply relative flex flex-col justify-center items-center w-full;
    }

    .step-item:not(:first-child):before {
        @apply content-[''] bg-slate-200 absolute w-full h-[3px] right-2/4 top-1/3 -translate-y-2/4;
    }

    .step {
        @apply w-10 h-10 flex items-center justify-center z-10 relative bg-primary rounded-full font-semibold text-white;
    }

    /* .activeStep .step,
    .bookRetakeActiveStep .step {
        @apply bg-primaryHover;
    } */

    .activeStep p,
    .bookRetakeActiveStep p {
        @apply text-primary font-semibold
    }

    .complete .step {
        @apply bg-primary;
    }

    .complete p {
        @apply text-primary;
    }

    .complete:not(:first-child):before,
    .activeStep:not(:first-child):before,
    .bookRetakeActiveStep:not(:first-child):before {
        @apply bg-primary;
    }

    @media (max-width: 550px) {
        .step {
            @apply w-8 h-8
        }
    }
    .c-text-header{
        @apply flex justify-center mt-[90px]
    }
    .mobile-next {
        @apply w-[264px]
    }
    .mobile-see-more {
        @apply hidden
    }
    @media not all and (min-width: 640px) {
        .mobile-see-more {
          @apply  flex flex-col sticky top-0 bg-white border border-b-gray-200 z-30 w-full shadow-md
        }
        .div-main-stepper {
            @apply hidden mr-0
        }
        .c-text-header{
            @apply p-10
        }
        .mobile-next {
            @apply w-[100px]
        }
    }

}

